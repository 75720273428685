<template>
  <section>
    <b-card>
      <b-card-title>
        <b-button
          variant="relief-primary"
          class="float-right"
          @click.stop="createModal = true"
        >
          Adicionar
        </b-button>
      </b-card-title>
      <br>
      <TablePagination
        v-model="items"
        url="/admin/draws/paginate"
        :per-page="10"
        :fields="fields"
      >
        <template v-slot:title="data">
          {{ data.items.title }}
        </template>

        <template v-slot:actions="data">
          <b-button
            :disabled="loading"
            class="mr-1"
            size="sm"
            variant="relief-primary"
            @click.stop="open(data.items.id)"
          >
            <feather-icon
              v-if="!loading"
              icon="EditIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            :disabled="loading"
            size="sm"
            variant="relief-danger"
            @click.stop="deleteModal(data.items)"
          >
            <feather-icon
              v-if="!loading"
              icon="Trash2Icon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>
        </template>
      </TablePagination>
    </b-card>

    <Create
      v-if="createModal"
      v-model="createModal"
      @updated="created($event)"
    />

    <Edit
      v-if="editModal"
      v-model="editModal"
      :data="editData"
      @updated="updated($event)"
    />
  </section>
</template>

<script>
import {
  BCard, BCardTitle, BButton, BSpinner
} from 'bootstrap-vue'
import IndexMixin from './IndexMixin'
import TablePagination from '@/components/TablePagination/Index.vue'
import Create from './Create.vue'
import Edit from '@/views/Admin/Draws/Edit.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BSpinner,
    TablePagination,
    Create,
    Edit
  },
  mixins: [IndexMixin],
  data: () => ({
    loading: false,
    createModal: false,
    editModal: false,
    editData: {},
    items: [],
    fields: [
      {
        key: 'title',
        label: 'Nome do sorteio'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    open (id) {
      this.editData = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.editData.first_free_number = !!this.editData.first_free_number
      this.editData.affiliate_free_number = !!this.editData.affiliate_free_number
      this.editModal = true
    },
    created (payload) {
      this.items.unshift(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar o sorteio "${item.title}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/draws/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'O sorteio selecionada foi deletada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
