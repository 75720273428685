<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="800px"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar Sorteio
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Nome"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="formData.title"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Valor por número"
            label-for="price_per_number"
          >
            <b-form-input
              id="price_per_number"
              v-model="formData.price_per_number"
              autofocus
              trim
              placeholder="Valor por número"
            />
          </b-form-group>

          <b-form-group
            label="Primeiro número grátis"
            label-for="first_free_number"
          >
            <b-form-checkbox
              id="first_free_number"
              v-model="formData.first_free_number"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Número Gratis por afiliado indicado"
            label-for="affiliate_free_number"
          >
            <b-form-checkbox
              id="affiliate_free_number"
              v-model="formData.affiliate_free_number"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Data de Ínicio de Sorteio"
            label-for="start"
          >
            {{ formData.start }}
            <DateTimePicker
              id="start"
              v-model="formData.start"
              :render="renderDatetimePicker"
              :display-current-date="true"
              :config="config"
              locale="pt"
              alt-timezone="America/Sao_Paulo"
              date-timezone="UTC"
            />
          </b-form-group>

          <b-form-group
            label="Data Final do Sorteio"
            label-for="end"
          >
            {{ formData.end }}
            <DateTimePicker
              id="end"
              v-model="formData.end"
              :render="renderDatetimePicker"
              :display-current-date="true"
              :config="config"
              locale="pt"
              alt-timezone="America/Sao_Paulo"
              date-timezone="UTC"
            />
          </b-form-group>

          <b-form-group
            label="Descrição"
            label-for="service-description"
          >
            <quill-editor v-model="formData.description" />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import DateTimePicker from '@/components/DateTimePicker.vue'

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    quillEditor,
    DateTimePicker
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    created_item: {},
    renderDatetimePicker: false,
    formData: {
      title: '',
      price_per_number: 0,
      first_free_number: false,
      affiliate_free_number: false,
      start: null,
      end: null,
      description: ''
    },
    getLocale: 'pt',
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    }
  }),
  mounted () {
    this.renderDatetimePicker = true
  },
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = '/admin/draws'
      const params = {
        ...this.formData
      }

      this.$http.post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Atualizado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
